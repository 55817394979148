import * as PropTypes from 'utils/proptypes';

import { createState, createStorage } from 'utils/state';

/**
 * State Name
 */

const NAME = 'PracticeMode';

/**
 * @typedef {Object} PracticeModeState
 * @property {Exercise} exercise
 */

/**
 * Initial State
 * @returns {PracticeModeState}
 */
function getInitialState() {
  return {
    exercise: null,
  };
}

/**
 * State Proptypes
 */
export const StatePropTypes = {
  exercise: PropTypes.Exercise,
};

/**
 * State Definition
 */

export const PracticeModeState = createState(
  NAME,
  getInitialState,
  StatePropTypes,
);

const { Storage: StateStorage, Manager: StateManager } = PracticeModeState;

/**
 * State Storage Definition
 */

StateStorage.exercise = createStorage(NAME, 'exercise', window.sessionStorage);

/**
 * State Change Listener
 */

StateManager.subscribe((prevState) => {
  if (!prevState) {
    return;
  }

  const { exercise } = StateManager.getState();
  const storedValue = StateStorage.exercise.get();
  if (exercise !== storedValue) {
    StateStorage.exercise.set(exercise);
  }
});

/**
 * Initialize Practice Mode State
 */

export async function initializePracticeMode() {
  const exercise = StateStorage.exercise.get();

  return StateManager.setState({
    exercise,
  });
}

/**
 *
 * @param {Exercise} exercise
 */
export function setPracticeModeExercise(exercise) {
  return StateManager.setState({ exercise });
}

/**
 *
 */
export function clearPracticeModeExercise() {
  return StateManager.setState({ exercise: null });
}
