import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, getDownloadURL, ref } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

// Load configuration, it is possible to set some defaults here as well.
const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_USE_LOCAL_FIREBASE_EMULATOR,
} = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

let firebaseApp;
let authApp;
let firestoreApp;
let functionsApp;

if (REACT_APP_USE_LOCAL_FIREBASE_EMULATOR === 'true') {
  firebaseApp = initializeApp({
    projectId: 'demo-loudly',
    appId: 'demo-loudly',
    apiKey: 'demo-loudly',
  });

  authApp = getAuth(firebaseApp);
  connectAuthEmulator(authApp, 'http://localhost:9099');

  firestoreApp = getFirestore(firebaseApp);
  connectFirestoreEmulator(firestoreApp, 'localhost', 8080);

  functionsApp = getFunctions(
    firebaseApp,
    process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION,
  );
  connectFunctionsEmulator(functionsApp, 'localhost', 5001);
} else {
  firebaseApp = initializeApp(config);
  authApp = getAuth(firebaseApp);
  firestoreApp = getFirestore(firebaseApp);
  functionsApp = getFunctions(
    firebaseApp,
    process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION,
  );
}

const firebase = firebaseApp;
const auth = authApp;
const firestore = firestoreApp;
const functions = functionsApp;

const storage = getStorage(firebase);

export { firebase, auth, firestore, storage, functions };

export async function getStorageUrl(path) {
  const url = await getDownloadURL(ref(storage, path));
  return url;
}
