import styled from 'styled-components';
import { media } from 'styles';

/**
 * @name H2
 * @description H2 component.
 * @property {string} [fontColor] - font color.
 * @example <H2 fontColor="red">Hello World</H2>
 */
const H2 = styled.h2`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 3.125rem;
  margin: 0;

  ${media.medium`
    font-size: 5.3125rem;
    line-height: 5.3125rem;
  `}
`;

export default H2;
