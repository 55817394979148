import React from 'react';
import * as PropTypes from 'utils/proptypes';

import { withRouter, Redirect } from 'react-router-dom';

import { AppState } from 'containers/App/state';
import { doSignOut } from 'utils/firebase/auth';
import { auth } from 'utils/firebase/firebase';
import jwtDecode from 'jwt-decode';
import { JWT } from 'loudly-shared/common/constants';

export const checkJWT = () => {
  // Check if JWT expired
  if (!localStorage.getItem(JWT.PLAYER) && auth.currentUser) {
    doSignOut();
  } else if (localStorage.getItem(JWT.PLAYER)) {
    // Check if Firebase is already logged out but there is still a JWT
    if (!auth.currentUser) {
      localStorage.removeItem(JWT.PLAYER);
    } else {
      const token = localStorage.getItem(JWT.PLAYER);
      const payload = jwtDecode(token);
      const dateNow = new Date();
      // Check if JWT has expired

      // Check if JWT firebaseUID equals firebase logged in user
      if (
        payload.exp < dateNow.getTime() / 1000 ||
        payload.firebaseUID !== auth.currentUser.uid
      ) {
        localStorage.removeItem(JWT.PLAYER);
        doSignOut();
      }
    }
  }
};

const withAuthenticatedUser = (Component) => {
  checkJWT();

  function withAuthenticatedUserImpl(props) {
    const { history, userAccount } = props;
    if (history.location.pathname.includes('/chapters') && !userAccount) {
      localStorage.setItem('lastLocation', history.location.pathname);
    }

    return (
      <AppState.Consumer>
        {({ authenticated }) =>
          authenticated ? <Component {...props} /> : <Redirect to="/" />
        }
      </AppState.Consumer>
    );
  }

  withAuthenticatedUserImpl.displayName = `withAuthenticatedUser(${
    Component.displayName || Component.name
  })`;

  const injectState = AppState.Connector(({ userAccount }) => ({
    userAccount,
  }));

  withAuthenticatedUserImpl.propTypes = {
    ...PropTypes.withRouting,
  };

  return withRouter(injectState(withAuthenticatedUserImpl));
};

export default withAuthenticatedUser;
