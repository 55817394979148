import styled from 'styled-components';
import { media } from 'styles';

/**
 * @name H5
 * @description H5 component.
 * @property {string} [fontColor] - font color.
 * @example <H5 fontColor="red">Hello World</H5>
 */
const H5 = styled.h5`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 1.5625rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin: 0;

  ${media.medium`
   font-size: 1.875rem;
   line-height: 2.1875rem;
 `}
`;

export default H5;
