import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { AppState } from 'containers/App/state';
import { translationMessages } from 'i18n';

/*
 * LanguageProvider
 *
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

function LanguageProvider({ children }) {
  return (
    <AppState.Consumer>
      {({ language }) => (
        <IntlProvider
          locale={language}
          key={language}
          messages={translationMessages[language]}
        >
          {React.Children.only(children)}
        </IntlProvider>
      )}
    </AppState.Consumer>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default memo(LanguageProvider);
