import styled from 'styled-components';

/**
 * @component
 * @deprecated This component is deprecated in favour of &lt;Text Small&gt; / &lt;Text&gt;
 */
const SmallText = styled.p`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 1em;
  line-height: 1.5;
  margin: 0;
`;

export default SmallText;
