/**
 *
 * App.tsx
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */
import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as routes from 'routes';
import AppTheme from 'components/AppTheme';
import LoadingIndicator from 'components/LoadingIndicator';
import GlobalStyle from 'styles/global';
import { injectIntl } from 'react-intl';
import { checkJWT } from 'helpers/withAuthenticatedUser';
import SSOCallback from 'containers/SSOCallback';
const LandingPage = lazy(() => import('containers/LandingPage/index'));
const SigninPage = lazy(() => import('containers/SigninPage'));
const ForgetPasswordPage = lazy(() => import('containers/ForgetPasswordPage'));
const HomePage = lazy(() => import('containers/HomePage'));
const RegistrationPage = lazy(() => import('containers/RegistrationPage'));
const ConfirmationPage = lazy(() => import('containers/ConfirmationPage'));
const VerificationPage = lazy(() => import('containers/VerificationPage'));
const NotFoundPage = lazy(() => import('containers/NotFoundPage'));
const ChaptersPage = lazy(() => import('containers/ChaptersPage'));
const AssignmentPreferences = lazy(() => import('containers/AssignmentPreferences'));
const Payments = lazy(() => import('containers/Payments'));
const PracticeMode = lazy(() => import('containers/PracticeMode'));
const SocialMode = lazy(() => import('containers/SocialMode'));
const SupportPage = lazy(() => import('containers/SupportPage'));
const PublishersPage = lazy(() => import('containers/PublishersPage'));
const ProfilePage = lazy(() => import('containers/ProfilePage'));
const TestPage = lazy(() => import('containers/TestPage'));
const TestCompleted = lazy(() => import('containers/TestPage/TestCompleted'));
const TestPreference = lazy(() => import('containers/TestPage/TestPreference'));
function App(props) {
    const { intl } = props;
    document.documentElement.lang = intl.locale;
    checkJWT();
    return (React.createElement(React.Fragment, null,
        React.createElement(Suspense, { fallback: React.createElement(LoadingIndicator, null) },
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: routes.LANDING_ROUTE, component: LandingPage }),
                React.createElement(Route, { exact: true, path: routes.SIGNIN_ROUTE, component: SigninPage }),
                React.createElement(Route, { exact: true, path: routes.SSO_CALLBACK_ROUTE, component: SSOCallback }),
                React.createElement(Route, { exact: true, path: routes.FORGET_PASSWORD_ROUTE, component: ForgetPasswordPage }),
                React.createElement(Route, { path: routes.REGISTRATION_ROUTE, component: RegistrationPage }),
                React.createElement(Route, { path: routes.CONFIRMATION_ROUTE, component: ConfirmationPage }),
                React.createElement(Route, { path: routes.VERIFY_ACCOUNT_ROUTE, component: VerificationPage }),
                React.createElement(Route, { path: routes.HOME_ROUTE, component: HomePage }),
                React.createElement(Route, { path: routes.CHAPTERS_ROUTE, component: ChaptersPage }),
                React.createElement(Route, { path: routes.ASSIGNMENT_PREFERENCES_ROUTE, component: AssignmentPreferences }),
                React.createElement(Route, { path: routes.PAYMENTS_ROUTE, component: Payments }),
                React.createElement(Route, { path: routes.PRACTICE_MODE_ROUTE, component: PracticeMode }),
                React.createElement(Route, { path: routes.SOCIAL_MODE_ROUTE, component: SocialMode }),
                React.createElement(Redirect, { from: routes.SOCIAL_MODE_REDIRECT, to: routes.SOCIAL_MODE_ROUTE }),
                React.createElement(Route, { path: routes.PUBLISHERS_ROUTE, component: PublishersPage }),
                React.createElement(Route, { path: routes.SUPPORT_ROUTE, component: SupportPage }),
                React.createElement(Route, { path: routes.PROFILE_ROUTE, component: ProfilePage }),
                React.createElement(Route, { path: routes.TEST_ROUTE, component: TestPage }),
                React.createElement(Route, { path: routes.TESTCOMPLETE_ROUTE, component: TestCompleted }),
                React.createElement(Route, { path: routes.TEST_PREFERENCES_ROUTE, component: TestPreference }),
                React.createElement(Route, { path: "/_/theme", component: AppTheme }),
                React.createElement(Route, { component: NotFoundPage }))),
        React.createElement(GlobalStyle, null)));
}
export default injectIntl(App);
