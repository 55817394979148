import styled from 'styled-components';
import { media } from 'styles';

/**
 * @name H1
 * @description H1 component.
 * @property {string} [fontColor] - font color.
 * @example <H1 fontColor="red">Hello World</H1>
 */
const H1 = styled.h1`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 4.0625rem;
  font-weight: 600;
  line-height: 4.0625rem;
  margin: 0;

  ${media.medium`
    font-size: 6.25rem;
    line-height: 6.25rem;
  `}
`;

export default H1;
