import React from 'react';

import styled from 'styled-components';

import { color, modeTheme } from 'styles';

import { ASSIGNMENT_MODE } from 'utils/constants';

import {
  Text,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  LargeText,
  SmallText,
} from 'components/Typography';

const Container = styled.div`
  margin: 2em auto;
  max-width: 800px;
`;

const ColorGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ColorItem = styled.div`
  align-items: center;
  background-color: ${({ value }) => value};
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-evenly;
  width: 200px;

  > code {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.6em;
  }
`;

const COLORS = Object.entries(color).filter(
  (item) => item[0].toUpperCase() !== item[0],
);

const ROLE_COLORS = [
  ['ACCENT_PRIMARY', color.ACCENT_PRIMARY],
  ['ACCENT_SECONDARY', color.ACCENT_SECONDARY],
  ['SUCCESS', color.SUCCESS],
  ['FAILURE', color.FAILURE],
];

function AppTheme() {
  return (
    <Container>
      <H3>Theme &amp; Typography</H3>
      <hr />

      <H1>H1</H1>
      <H2>H2</H2>
      <H3>H3</H3>
      <H4>H4</H4>
      <H5>H5</H5>
      <H6>H6</H6>
      <br />

      <H6>&lt;Text Large&gt;</H6>
      <Text Large>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry&apos;s standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Text>
      <br />

      <H6>&lt;Text Medium&gt;</H6>
      <Text Medium>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry&apos;s standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Text>
      <br />

      <H6>&lt;Text Small&gt; or &lt;Text&gt;</H6>
      <Text Small>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry&apos;s standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Text>
      <br />

      <H6>
        &lt;Text fontWeightBold&gt; or &lt;Text
        fontWeightBold=&quot;600&quot;&gt;
      </H6>
      <Text fontWeightBold>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry&apos;s standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Text>
      <br />

      <H6>
        &lt;Text fontColor=&#123;color.brilliantAzure&#125;&gt; or &lt;Text
        fontColor=&quot;#3c9df9&quot;&gt;
      </H6>
      <Text fontColor={color.brilliantAzure}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry&apos;s standard dummy text
        ever since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Text>
      <br />
      <hr />

      <details>
        <summary>Deprecated styles</summary>
        <H6>&lt;p&gt;</H6>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry&apos;s standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book. It has survived not
          only five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <br />

        <H6>&lt;LargeText&gt;</H6>
        <LargeText>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry&apos;s standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book.
        </LargeText>
        <br />

        <H6>&lt;SmallText&gt;</H6>
        <SmallText>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry&apos;s standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book.
        </SmallText>
      </details>

      <hr />

      <h2>Colors</h2>

      <ColorGrid>
        {COLORS.map(([colorName, colorValue]) => (
          <ColorItem key={colorName} value={colorValue}>
            <code>{colorName}</code>
            <code>{colorValue}</code>
          </ColorItem>
        ))}
      </ColorGrid>

      <h2>Role Colors</h2>

      <ColorGrid>
        {ROLE_COLORS.map(([colorName, colorValue]) => (
          <ColorItem key={colorName} value={colorValue}>
            <code>{colorName}</code>
            <code>{colorValue}</code>
          </ColorItem>
        ))}
      </ColorGrid>

      <hr />

      <h2>Mode Colors</h2>

      <h3>Practice Mode</h3>

      <ColorGrid>
        <ColorItem value={modeTheme[ASSIGNMENT_MODE.PRACTICE].primaryColor}>
          <code>primaryColor</code>
          <code>{modeTheme[ASSIGNMENT_MODE.PRACTICE].primaryColor}</code>
        </ColorItem>
        <ColorItem value={modeTheme[ASSIGNMENT_MODE.PRACTICE].secondaryColor}>
          <code>secondaryColor</code>
          <code>{modeTheme[ASSIGNMENT_MODE.PRACTICE].secondaryColor}</code>
        </ColorItem>
      </ColorGrid>

      <h3>Social Mode</h3>

      <ColorGrid>
        <ColorItem value={modeTheme[ASSIGNMENT_MODE.SOCIAL].primaryColor}>
          <code>primaryColor</code>
          <code>{modeTheme[ASSIGNMENT_MODE.SOCIAL].primaryColor}</code>
        </ColorItem>
        <ColorItem value={modeTheme[ASSIGNMENT_MODE.SOCIAL].secondaryColor}>
          <code>secondaryColor</code>
          <code>{modeTheme[ASSIGNMENT_MODE.SOCIAL].secondaryColor}</code>
        </ColorItem>
      </ColorGrid>

      <h3>Competition Mode</h3>

      <ColorGrid>
        <ColorItem value={modeTheme[ASSIGNMENT_MODE.COMPETITION].primaryColor}>
          <code>primaryColor</code>
          <code>{modeTheme[ASSIGNMENT_MODE.COMPETITION].primaryColor}</code>
        </ColorItem>
        <ColorItem
          value={modeTheme[ASSIGNMENT_MODE.COMPETITION].secondaryColor}
        >
          <code>secondaryColor</code>
          <code>{modeTheme[ASSIGNMENT_MODE.COMPETITION].secondaryColor}</code>
        </ColorItem>
      </ColorGrid>

      <hr />
      <H6>Notes &amp; References</H6>
      <Text>
        The typography on this page, and hopefully the colors, too, were based
        on the{' '}
        <a href="https://zeroheight.com/865258149/p/138411-loudly-design-library">
          Loudly Design Library
        </a>
        .
      </Text>
      <br />
    </Container>
  );
}

export default AppTheme;
