var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { updateProfile } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { functions } from 'utils/firebase/firebase';
import { AppState } from 'containers/App/state';
import { doCreateUserWithEmailAndPassword, doSignInWithEmailAndPassword, } from 'utils/firebase/auth';
import jwtDecode from 'jwt-decode';
import { ERROR_EMAIL_ALREADY_IN_USE } from 'utils/firebase/errorCode';
import LoginUserWithEmailPassword from './login';
import connectUserToFirebaseUID from './connect';
import SSOAuthentication from './sso';
const createUserFirebase = (authUser, password, name, token) => __awaiter(void 0, void 0, void 0, function* () {
    yield updateProfile(authUser.user, { displayName: name });
    const createDocInFirestore = httpsCallable(functions, 'createUserDocument');
    yield createDocInFirestore({
        displayName: authUser.user.displayName,
    });
    return connectUserToFirebaseUID({
        firebaseUID: authUser.user.uid,
        firebasePassword: password,
    }, token).then((JWT) => {
        return JWT.data.token;
    });
});
const changeToOldFirebase = (email, password, token) => __awaiter(void 0, void 0, void 0, function* () {
    const updateUser = httpsCallable(functions, 'updateUserPassword');
    yield updateUser({
        email,
        password,
    });
    return doSignInWithEmailAndPassword(email, password).then((result) => __awaiter(void 0, void 0, void 0, function* () {
        return connectUserToFirebaseUID({ firebaseUID: result.user.uid, firebasePassword: password }, token).then((JWT) => {
            return JWT.data.token;
        });
    }));
});
const registerUserFirebase = (email, password, name, token) => __awaiter(void 0, void 0, void 0, function* () {
    return doCreateUserWithEmailAndPassword(email, password)
        .then((user) => __awaiter(void 0, void 0, void 0, function* () {
        return createUserFirebase(user, password, name, token);
    }))
        .catch((error) => __awaiter(void 0, void 0, void 0, function* () {
        if (error.code === ERROR_EMAIL_ALREADY_IN_USE) {
            return changeToOldFirebase(email, password, token);
        }
        throw error.code;
    }));
});
/**
 * Service that will handle all required login logic
 * @param email Email of the user
 * @param password Password of the user
 * @returns JWT token
 */
export const Service = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    return LoginUserWithEmailPassword({
        email,
        password,
        preferredLanguage: AppState.Manager.getState().language,
    })
        .then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response.data.token) {
            const { token } = response.data;
            const payload = jwtDecode(token);
            const { name } = payload;
            if (payload.firebaseUID) {
                yield doSignInWithEmailAndPassword(email, payload.firebasePassword);
                return token;
            }
            return registerUserFirebase(email, payload.firebasePassword, name, token);
        }
        throw response.data;
    }))
        .catch((err) => {
        throw err.response.data.responseId;
    });
});
/**
 * Service that will handle all required SSO logic
 * @param accessToken Token retrieved from ITSCMS
 * @returns JWT token
 */
export const SSOService = (accessToken) => __awaiter(void 0, void 0, void 0, function* () {
    return SSOAuthentication({
        token: accessToken,
    })
        .then((response) => __awaiter(void 0, void 0, void 0, function* () {
        if (response.data.token) {
            const { token } = response.data;
            const payload = jwtDecode(token);
            const { email } = payload;
            if (payload.firebaseUID) {
                yield doSignInWithEmailAndPassword(email, payload.firebasePassword);
                return token;
            }
            const name = email.substring(0, email.lastIndexOf('@'));
            return registerUserFirebase(email, payload.firebasePassword, name, token);
        }
        throw response.data;
    }))
        .catch((err) => {
        throw err.response.data.responseId;
    });
});
