/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/**
 * SSOCallback page
 * Return to this page after SSO to handle the login/register
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import LoadingIndicator from 'components/LoadingIndicator';
import { JWT } from 'loudly-shared/common/constants';
import React, { useEffect } from 'react';
import { HOME_ROUTE, LANDING_ROUTE } from 'routes';
import { SSOService } from 'utils/auth/authservice';
function SSOCallback(props) {
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const action = query.get('action');
        const token = query.get('user_token');
        if ((action === 'login' || action === 'register') && token) {
            SSOService(token)
                .then((result) => __awaiter(this, void 0, void 0, function* () {
                if (result) {
                    localStorage.setItem(JWT.PLAYER, result);
                }
                // eslint-disable-next-line react/destructuring-assignment
                props.history.push(HOME_ROUTE);
                setTimeout(() => {
                    if (window.location.pathname !== HOME_ROUTE) {
                        window.location.reload();
                    }
                }, 1000);
            }))
                .catch(() => {
                props.history.push(LANDING_ROUTE);
            });
        }
        else {
            props.history.push(LANDING_ROUTE);
        }
    });
    return React.createElement(LoadingIndicator, null);
}
export default SSOCallback;
