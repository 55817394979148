import * as PropTypes from 'utils/proptypes';

import { createState } from 'utils/state';

import { PARTY_ROOM_STATE } from 'utils/constants';

import { firestore } from 'utils/firebase/firebase';
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  orderBy,
  limit,
  query,
  updateDoc,
  onSnapshot,
  deleteDoc,
  setDoc,
  serverTimestamp,
  arrayUnion,
} from 'firebase/firestore';

import { PARTY_ROOMS_COLLECTION } from 'loudly-shared/firebase/collections';

import {
  fetchQuestionCollection,
  fetchQuestionDocument,
} from 'utils/firebase/service';

import { AppState } from 'containers/App/state';

import { computePartyRoomRanks } from './service';

/**
 * State Name
 */

const NAME = 'SocialMode';

/**
 * @typedef {Object} SocialModeState
 * @property {PartyRoom & PartyRoomComputed} partyRoom
 * @property {boolean} currentUserIsOrganizer
 */

/**
 * Initial State
 * @returns {SocialModeState}
 */
function getInitialState() {
  return {
    partyRoom: null,
    currentUserIsOrganizer: false,
  };
}

/**
 * State Proptypes
 */
export const StatePropTypes = {
  partyRoom: PropTypes.PartyRoom,
  currentUserIsOrganizer: PropTypes.bool,
};

/**
 * State Definition
 */
export const SocialModeState = createState(
  NAME,
  getInitialState,
  StatePropTypes,
);

const { Manager: StateManager } = SocialModeState;

/**
 * State Change Listener
 */

StateManager.subscribe((prevState) => {
  if (!prevState) {
    return;
  }

  const state = StateManager.getState();

  if (
    state.partyRoom &&
    prevState.partyRoom &&
    state.partyRoom.state !== prevState.partyRoom.state
  ) {
    window.scrollTo(0, 0);
  }
});

/**
 * Load party room with realtime updates
 */

let observer = null;

export async function loadAndObservePartyRoom(partyRoomId) {
  if (!partyRoomId) {
    throw new Error('Party room not found');
  }

  const { userId } = AppState.Manager.getState();

  const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoomId}`);

  const document = await getDoc(ref);

  if (!document.exists()) {
    throw new Error('Party room not found');
  }

  return new Promise((resolve) => {
    let firstRun = true;
    observer = onSnapshot(
      ref,
      { includeMetadataChanges: true },
      async (documentSnapshot) => {
        if (
          documentSnapshot.metadata.hasPendingWrites ||
          documentSnapshot.metadata.fromCache
        ) {
          return;
        }

        if (!documentSnapshot.exists()) {
          StateManager.resetState();

          return;
        }

        const previousRecored = StateManager.getState().partyRoom;

        const record = {
          id: documentSnapshot.id,
          ...documentSnapshot.data(),
        };

        if (record.archived) {
          await StateManager.resetState();

          return;
        }

        record.playerListComputed = Object.entries(record.playerById)
          .map(([id, data]) => ({ id, ...data }))
          .sort((a, b) => a.joinedAt.toMillis() - b.joinedAt.toMillis());

        record.playerRankListComputed = Object.entries(record.playerRankById)
          .map(([id, data]) => ({ id, ...data }))
          .sort((a, b) => b.score - a.score);

        record.activeQuestionIndexComputed = 0;
        record.activeQuestionComputed = null;

        if (record.activeQuestionId) {
          record.activeQuestionIndexComputed = record.questionIds.indexOf(
            record.activeQuestionId,
          );

          if (
            previousRecored &&
            previousRecored.activeQuestionId === record.activeQuestionId &&
            previousRecored.activeQuestionComputed
          ) {
            record.activeQuestionComputed =
              previousRecored.activeQuestionComputed;
          } else {
            record.activeQuestionComputed = await fetchQuestionDocument({
              methodId: record.methodId,
              chapterId: record.chapterId,
              wordlistId: record.wordlistId,
              questionId: record.activeQuestionId,
            });
          }
        }

        const result = {
          partyRoom: record,
          currentUserIsOrganizer: record.organizerId === userId,
        };

        await StateManager.setState(result);

        if (firstRun) {
          firstRun = false;
          resolve(result);
        }
      },
    );
  });
}

export function clearAndUnobservePartyRoom() {
  StateManager.setState({
    partyRoom: null,
    currentUserIsOrganizer: false,
  });

  if (observer) {
    observer();
  }
}

/**
 * Initialize Social Mode State
 */

export async function initializeSocialModeState() {
  const { userId } = AppState.Manager.getState();

  if (!userId) {
    return null;
  }

  let partyRoomId = null;

  {
    const q = query(
      collection(firestore, PARTY_ROOMS_COLLECTION),
      where('organizerId', '==', userId),
      where('archived', '==', false),
      orderBy('createdAt', 'desc'),
      limit(1),
    );
    const result = await getDocs(q);

    const item = result.docs[0];

    if (item) {
      partyRoomId = item.id;
    }
  }

  if (!partyRoomId) {
    const q = query(
      collection(firestore, PARTY_ROOMS_COLLECTION),
      where('playerIds', 'array-contains', userId),
      where('archived', '==', false),
      orderBy('createdAt', 'desc'),
      limit(1),
    );
    const result = await getDocs(q);

    const item = result.docs[0];

    if (item) {
      partyRoomId = item.id;
    }
  }

  if (partyRoomId) {
    return loadAndObservePartyRoom(partyRoomId);
  }

  return null;
}

/** ************************************************** */
/** ************** Organizer Operations ************** */
/** ************************************************** */

const PARTY_ROOM_ID_SYMBOLS = '0123456789';
const PARTY_ROOM_ID_LENGTH = 6;

function generatePartyRoomId() {
  let id = '';
  for (let i = 0; i < PARTY_ROOM_ID_LENGTH; i += 1) {
    id += PARTY_ROOM_ID_SYMBOLS[Math.floor(Math.random() * 10)];
  }
  return id;
}

/**
 * Organize a party room as organizer
 */
export async function organizePartyRoom({
  methodId = null,
  chapterId = null,
  wordlistId = null,
  ...exercise
}) {
  const { userId } = AppState.Manager.getState();

  const questionsDoc = await fetchQuestionCollection({
    methodId,
    chapterId,
    wordlistId,
  });

  const questionIds = questionsDoc.map(({ id }) => id);
  const ref = doc(
    firestore,
    `${PARTY_ROOMS_COLLECTION}/${generatePartyRoomId()}`,
  );

  if ((await getDoc(ref)).exists()) {
    await deleteDoc(ref);
  }

  const record = {
    state: PARTY_ROOM_STATE.PENDING,
    archived: false,
    createdAt: serverTimestamp(),
    startedAt: null,
    closedAt: null,
    organizerId: userId,
    playerIds: [],
    playerById: {},
    questionIds,
    questionTimestampById: {},
    activeQuestionId: null,
    playerAnswerByPlayerIdByQuestionId: {},
    playerRankById: {},
    methodId,
    chapterId,
    wordlistId,
    ...exercise,
  };

  await setDoc(ref, record);

  return loadAndObservePartyRoom(ref.id);
}

StateManager.organizePartyRoom = organizePartyRoom;

if (process.env.NODE_ENV === 'development') {
  /**
   * Reset active party room as organizer
   */
  // eslint-disable-next-line no-inner-declarations
  async function resetPartyRoomAsOrganizer(full = false) {
    const { partyRoom, currentUserIsOrganizer } = StateManager.getState();

    if (!partyRoom) {
      throw new Error('Party room not found');
    }

    if (!currentUserIsOrganizer) {
      throw new Error('Unauthorized access to party room');
    }
    const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoom.id}`);
    await updateDoc(ref, {
      state: PARTY_ROOM_STATE.PENDING,
      startedAt: null,
      closedAt: null,
      questionTimestampById: {},
      activeQuestionId: null,
      ...(full
        ? {
            playerAnswerByPlayerIdByQuestionId: {},
            playerRankById: {},
          }
        : null),
    });
  }

  StateManager.resetPartyRoomAsOrganizer = resetPartyRoomAsOrganizer;
}

/**
 * Start active party room as organizer
 */
export async function startPartyRoomAsOrganizer() {
  const { partyRoom, currentUserIsOrganizer } = StateManager.getState();

  if (!partyRoom) {
    throw new Error('Party room not found');
  }

  if (!currentUserIsOrganizer) {
    throw new Error('Unauthorized access to party room');
  }

  const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoom.id}`);
  const activeQuestionId = partyRoom.questionIds[0];
  await updateDoc(ref, {
    state: PARTY_ROOM_STATE.ONGOING_QUESTION,
    [`questionTimestampById.${activeQuestionId}`]: serverTimestamp(),
    activeQuestionId,
    startedAt: serverTimestamp(),
  });
}

StateManager.startPartyRoomAsOrganizer = startPartyRoomAsOrganizer;

/**
 * Progress active party room as organizer
 */
export async function continuePartyRoomAsOrganizer() {
  const { partyRoom, currentUserIsOrganizer } = StateManager.getState();

  if (!partyRoom) {
    throw new Error('Party room not found');
  }

  if (!currentUserIsOrganizer) {
    throw new Error('Unauthorized access to party room');
  }

  if (!partyRoom.activeQuestionId) {
    return;
  }

  const activeQuestionIdIndex = partyRoom.questionIds.indexOf(
    partyRoom.activeQuestionId,
  );

  if (activeQuestionIdIndex === -1) {
    return;
  }

  const done =
    activeQuestionIdIndex + 1 === partyRoom.questionIds.length &&
    partyRoom.state === PARTY_ROOM_STATE.ONGOING_ANSWER;

  let state;
  let activeQuestionId;

  if (partyRoom.state === PARTY_ROOM_STATE.ONGOING_QUESTION) {
    state = PARTY_ROOM_STATE.ONGOING_ANSWER;
    activeQuestionId = partyRoom.questionIds[activeQuestionIdIndex];
  } else if (activeQuestionIdIndex + 1 < partyRoom.questionIds.length) {
    state = PARTY_ROOM_STATE.ONGOING_QUESTION;
    activeQuestionId = partyRoom.questionIds[activeQuestionIdIndex + 1];
  }

  if (done) {
    // eslint-disable-next-line no-use-before-define
    await closePartyRoomAsOrganizer();
  } else {
    const playerRankById = await computePartyRoomRanks(partyRoom);
    const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoom.id}`);
    await updateDoc(ref, {
      state,
      ...(state === PARTY_ROOM_STATE.ONGOING_QUESTION && {
        [`questionTimestampById.${activeQuestionId}`]: serverTimestamp(),
      }),
      activeQuestionId,
      playerRankById,
    });
  }
}

StateManager.continuePartyRoomAsOrganizer = continuePartyRoomAsOrganizer;

/**
 * Close active party room as organizer
 */
export async function closePartyRoomAsOrganizer() {
  const { partyRoom, currentUserIsOrganizer } = StateManager.getState();

  if (!partyRoom) {
    throw new Error('Party room not found');
  }

  if (!currentUserIsOrganizer) {
    throw new Error('Unauthorized access to party room');
  }

  const playerRankById = await computePartyRoomRanks(partyRoom);

  const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoom.id}`);

  await updateDoc(ref, {
    state: PARTY_ROOM_STATE.DONE,
    activeQuestionId: null,
    closedAt: serverTimestamp(),
    playerRankById,
  });
}

StateManager.closePartyRoomAsOrganizer = closePartyRoomAsOrganizer;

/**
 * Archive active party room as organizer
 */
export async function archivePartyRoomAsOrganizer() {
  const { partyRoom, currentUserIsOrganizer } = StateManager.getState();

  if (!partyRoom) {
    throw new Error('Party room not found');
  }

  if (!currentUserIsOrganizer) {
    throw new Error('Unauthorized access to party room');
  }

  const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoom.id}`);

  await updateDoc(ref, {
    archived: true,
  });
}

StateManager.archivePartyRoomAsOrganizer = archivePartyRoomAsOrganizer;

/** ************************************************** */
/** ************** Player Operations ***************** */
/** ************************************************** */

/**
 * Join party room as player
 * @param {string} partyRoomId
 */
export async function joinPartyRoomAsPlayer(
  partyRoomId,
  displayName,
  initials,
) {
  const result = await loadAndObservePartyRoom(partyRoomId);

  const { partyRoom } = result;

  if (partyRoom.state !== PARTY_ROOM_STATE.PENDING) {
    throw new Error('Party room is not available anymore');
  }

  const { userId } = AppState.Manager.getState();

  const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoomId}`);
  await updateDoc(ref, {
    playerIds: arrayUnion(userId),
    [`playerById.${userId}`]: {
      displayName,
      initials,
      joinedAt: serverTimestamp(),
    },
  });

  return result;
}

StateManager.joinPartyRoomAsPlayer = joinPartyRoomAsPlayer;

/**
 * Answer question as player
 * @param {*} answer
 * @param {boolean} answerIsCorrect
 */
export async function answerQuestionAsPlayer(answer, answerIsCorrect) {
  const { partyRoom } = StateManager.getState();

  if (!partyRoom) {
    throw new Error('Party room not found');
  }

  if (partyRoom.state !== PARTY_ROOM_STATE.ONGOING_QUESTION) {
    throw new Error("Party room doesn't accept answers anymore");
  }

  const { userId } = AppState.Manager.getState();

  const ref = doc(firestore, `${PARTY_ROOMS_COLLECTION}/${partyRoom.id}`);
  await updateDoc(ref, {
    [`playerAnswerByPlayerIdByQuestionId.${userId}.${partyRoom.activeQuestionId}`]:
      {
        playerId: userId,
        questionId: partyRoom.activeQuestionId,
        answer,
        answerIsCorrect,
        timestamp: serverTimestamp(),
      },
  });
}

StateManager.answerQuestionAsPlayer = answerQuestionAsPlayer;
