import { firestore } from 'utils/firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { USERS_COLLECTION } from 'loudly-shared/firebase/collections';

/**
 *
 * @param {ID} userId
 */
export function referenceUserProfile(userId) {
  return doc(firestore, `${USERS_COLLECTION}/${userId}`);
}

/**
 *
 * @param {ID} userId
 * @returns {Promise<UserProle>}
 */
export async function fetchUserProfile(userId) {
  const snapshot = await getDoc(referenceUserProfile(userId));
  return snapshot.data();
}
