import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithRedirect,
  getRedirectResult,
  signInAnonymously,
  signOut,
} from 'firebase/auth';
import { auth } from './firebase';
// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

// Sign out
export const doSignOut = async () => {
  await signOut(auth);
  window.localStorage.clear();
  window.sessionStorage.clear();
};

// Password reset
export const doPasswordReset = (email, actionCodeSettings) =>
  sendPasswordResetEmail(auth, email, actionCodeSettings);

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

export const signInWithMicrosoftRedirect = (provider) =>
  signInWithRedirect(auth, provider);

export const getMicrosoftRedirectResult = () => getRedirectResult(auth);

export const doSignInAnonymously = () => signInAnonymously(auth);
