import * as PropTypes from 'utils/proptypes';

import { createState, createStorage } from 'utils/state';

import { ASSIGNMENT_COMPETENCE_ID } from 'utils/constants';

/**
 * State Name
 */

const NAME = 'Exercise';

/**
 * @typedef {Object} ExerciseState
 * @property {Exercise} assignmentPreferences
 */

/**
 * Initial State
 * @returns {ExerciseState}
 */
function getInitialState() {
  return {
    assignmentPreferences: null,
  };
}

/**
 * State Proptypes
 */
export const StatePropTypes = {
  assignmentPreferences: PropTypes.Exercise,
};

/**
 * State Definition
 */

export const ExerciseState = createState(NAME, getInitialState, StatePropTypes);

const { Storage: StateStorage, Manager: StateManager } = ExerciseState;

/**
 * State Storage Definition
 */

StateStorage.assignmentPreferences = createStorage(
  NAME,
  'assignmentPreferences',
  window.sessionStorage,
);

StateStorage[ASSIGNMENT_COMPETENCE_ID.VOCABULARY_AND_UNDERSTANDING] =
  createStorage(
    NAME,
    ASSIGNMENT_COMPETENCE_ID.VOCABULARY_AND_UNDERSTANDING,
    window.sessionStorage,
  );

StateStorage[ASSIGNMENT_COMPETENCE_ID.VOCABULARY_AND_SPELLING] = createStorage(
  NAME,
  ASSIGNMENT_COMPETENCE_ID.VOCABULARY_AND_SPELLING,
  window.sessionStorage,
);

StateStorage[ASSIGNMENT_COMPETENCE_ID.VOCABULARY_AND_SPEAKING] = createStorage(
  NAME,
  ASSIGNMENT_COMPETENCE_ID.VOCABULARY_AND_SPEAKING,
  window.sessionStorage,
);

StateStorage[ASSIGNMENT_COMPETENCE_ID.SPEECH_THERAPY] = createStorage(
  NAME,
  ASSIGNMENT_COMPETENCE_ID.SPEECH_THERAPY,
  window.sessionStorage,
);

StateStorage[ASSIGNMENT_COMPETENCE_ID.FLASHCARD] = createStorage(
  NAME,
  ASSIGNMENT_COMPETENCE_ID.FLASHCARD,
  window.sessionStorage,
);

/**
 * State Change Listener
 */
StateManager.subscribe((prevState) => {
  if (!prevState) {
    return;
  }

  const { assignmentPreferences } = StateManager.getState();
  const storedValue = StateStorage.assignmentPreferences.get();
  if (assignmentPreferences !== storedValue) {
    StateStorage.assignmentPreferences.set(assignmentPreferences);
  }
});

/**
 * Initialize Practice Mode State
 */

export async function initializeAssignmentPreferences() {
  const assignmentPreferences = StateStorage.assignmentPreferences.get();

  return StateManager.setState({
    assignmentPreferences,
  });
}

/**
 *
 * @param {Exercise} changes
 * @param {boolean} reset
 */
export function saveAssignmentPreferences(changes, reset = false) {
  return StateManager.setState(({ assignmentPreferences }) => ({
    assignmentPreferences: reset
      ? changes
      : { ...assignmentPreferences, ...changes },
  }));
}
