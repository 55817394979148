const ORDER_FIELD = 'order';

/**
 *
 * @param {*} a
 * @param {*} b
 */
export function orderFieldSorter(a, b) {
  return ORDER_FIELD in a && ORDER_FIELD in b ? a.order - b.order : 0;
}

/**
 *
 * @param {Method} a
 * @param {Method} b
 */
export function methodSorter(a, b) {
  return (
    orderFieldSorter(a, b) ||
    a.publisher.localeCompare(b.publisher) ||
    a.name.localeCompare(b.name)
  );
}

/**
 *
 * @param {Chapter} a
 * @param {Chapter} b
 */
export function chapterSorter(a, b) {
  return orderFieldSorter(a, b) || a.name.localeCompare(b.name);
}

/**
 *
 * @param {Wordlist} a
 * @param {Wordlist} b
 */
export function wordlistSorter(a, b) {
  return orderFieldSorter(a, b);
}

/**
 *
 * @param {Student} a
 * @param {Student} b
 */
export function studentSorter(a, b) {
  return a.name.localeCompare(b.name);
}

/**
 *
 * @param {Group} a
 * @param {Group} b
 */
export function groupSorter(a, b) {
  return a.name.localeCompare(b.name);
}

/**
 *
 * @param {Question} a
 * @param {Question} b
 */
export function questionSorter(a, b) {
  if (!Number.isNaN(Number(a.id)) && !Number.isNaN(Number(b.id))) {
    return parseFloat(a.id) - parseFloat(b.id);
  }
  return true;
}

/**
 *
 * @param {Test} a
 * @param {Test} b
 */
export function testSorter(a, b) {
  return (
    orderFieldSorter(a, b) ||
    a.publisher.localeCompare(b.publisher) ||
    a.name.localeCompare(b.name)
  );
}
