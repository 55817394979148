import styled from 'styled-components';

/**
 * @component
 * @deprecated This component is deprecated in favour of &lt;Text Large&gt;
 */
const LargeText = styled.p`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 1.25em;
  font-weight: ${({ fontWeightBold }) => (fontWeightBold ? 600 : 'normal')};
  line-height: 1.5;
  margin: 0;
`;

export default LargeText;
