import React from 'react';

import LanguageProvider from 'helpers/LanguageProvider';
import { SentryErrorBoundary } from 'sentry';
import { Router } from 'react-router-dom';
import history from 'utils/history';

import App from 'containers/App';
import LoadingIndicator from 'components/LoadingIndicator';
import ResetScrollOnRouteChange from 'helpers/ResetScrollOnRouteChange';
import { GlobalStateProvider } from 'utils/state';
import { AppState, initializeAppState } from 'containers/App/state';

import {
  ExerciseState,
  initializeAssignmentPreferences,
} from 'containers/Exercise/state';

import {
  PracticeModeState,
  initializePracticeMode,
} from 'containers/PracticeMode/state';

import {
  SocialModeState,
  initializeSocialModeState,
} from 'containers/SocialMode/state';

async function setupState(container) {
  AppState.Manager.setup(container);
  ExerciseState.Manager.setup(container);
  PracticeModeState.Manager.setup(container);
  SocialModeState.Manager.setup(container);
}

async function initializeState() {
  await initializeAppState();
  await initializeAssignmentPreferences();
  await initializePracticeMode();
  await initializeSocialModeState();
}

class AppContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    setupState(this);
    initializeState().then(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <LoadingIndicator />;
    }

    return (
      <SentryErrorBoundary>
        <GlobalStateProvider value={this.state}>
          <LanguageProvider>
            <Router history={history}>
              <ResetScrollOnRouteChange />
              <App />
            </Router>
          </LanguageProvider>
        </GlobalStateProvider>
      </SentryErrorBoundary>
    );
  }
}

export default AppContainer;
