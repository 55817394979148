import styled from 'styled-components';
import { media } from 'styles';

/**
 * @name H4
 * @description H4 component.
 * @property {string} [fontColor] - font color.
 * @example <H4 fontColor="red">Hello World</H4>
 */
const H4 = styled.h4`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin: 0;

  ${media.medium`
   font-size: 2.5rem;
   line-height: 2.5rem;
 `}
`;

export default H4;
