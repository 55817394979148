/* eslint-disable import/no-webpack-loader-syntax */
/**
 * app.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
// Import Sentry, this way it gets initialised right away, before we do anything else!
import 'sentry';
// Import all the third party stuff
import React from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { createRoot } from 'react-dom/client';
import 'sanitize.css/sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
// Logo
import '!file-loader?name=./[name].[ext]!./images/logo.svg';
import '!file-loader?name=./[name].[ext]!./images/icon.svg';
// Favicon
import '!file-loader?name=./favicon/[name].[ext]!./favicon/apple-touch-icon.png';
import '!file-loader?name=./favicon/[name].[ext]!./favicon/favicon-16x16.png';
import '!file-loader?name=./favicon/[name].[ext]!./favicon/favicon-32x32.png';
import '!file-loader?name=./[name].[ext]!./favicon/browserconfig.xml';
import '!file-loader?name=./favicon/[name].[ext]!./favicon/mstile-150x150.png';
import '!file-loader?name=./favicon/[name].[ext]!./favicon/safari-pinned-tab.svg';
import '!file-loader?name=./[name].[ext]!./favicon/favicon.ico';
// PWA
import '!file-loader?name=./[name].[ext]!./manifest.json';
import '!file-loader?name=./pwa/[name].[ext]!./pwa/app-64x64.png';
import '!file-loader?name=./pwa/[name].[ext]!./pwa/app-192x192.png';
import '!file-loader?name=./pwa/[name].[ext]!./pwa/app-512x512.png';
import AppContainer from 'AppContainer';
// Import i18n
import './i18n';
// Observe loading of theme font
if (process.env.REACT_APP_THEME_FONT_FAMILY) {
    const themeFontObserver = new FontFaceObserver(process.env.REACT_APP_THEME_FONT_FAMILY, {});
    // When theme font is loaded, set it to body
    themeFontObserver.load().then(() => document.body.classList.add('font-loaded'), () => document.body.classList.remove('font-loaded'));
}
const container = document.getElementById('app');
const root = createRoot(container);
root.render(React.createElement(AppContainer, null));
