import { createGlobalStyle } from 'styled-components';

import { debounce } from 'utils/utils';

let vh = window.innerHeight * 0.01;

window.addEventListener(
  'resize',
  debounce(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }),
);

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar-track {
    background-color: rgba(245,245,245, 0.2);
  }

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(182, 189, 195, 0.3);
  }

  :root {
    --vh: ${vh}px;
  }

  html {
    height: 100%;
    line-height: 1;
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    overflow-y: auto;
    overflow-y: overlay;
  }

  body.font-loaded {
    font-family: ${process.env.REACT_APP_THEME_FONT_FAMILY}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: calc(var(--vh, 1vh) * 100);
  }
`;

export default GlobalStyle;
