import styled from 'styled-components';
import { media } from 'styles';

/**
 * @name H3
 * @description H3 component.
 * @property {string} [fontColor] - font color.
 * @property [extraMargin] - extra margin on the bottom.
 * @example <H3 fontColor="red">Hello World</H3>
 */
const H3 = styled.h3`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin: ${(props) => (props.extraMargin ? '0 0 0.4em' : '0')};

  ${media.medium`
    font-size: 4.0625rem;
    line-height: 4.0625rem;
  `}
`;

export default H3;
