import styled from 'styled-components';

/**
 * @name H6
 * @description H6 component.
 * @property {string} [fontColor] - font color.
 * @example <H6 fontColor="red">Hello World</H6>
 */
const H6 = styled.h6`
  ${({ fontColor }) => fontColor && `color: ${fontColor}`};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.5625rem;
  margin: 0;
`;

export default H6;
