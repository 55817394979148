import * as PropTypes from 'utils/proptypes';

import { debounce } from 'utils/utils';

import { createState } from 'utils/state';

import { USER_ROLE, LANGUAGE_CODE } from 'utils/constants';

import { USERS_COLLECTION } from 'loudly-shared/firebase/collections';

import { auth, firestore } from 'utils/firebase/firebase';

import { onIdTokenChanged, getIdToken } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

import { fetchUserProfile } from './service';

/*
 * Detect language preference from the browser and return that as locale.
 */
export const getNavigatorLanguage = () => {
  if (navigator.languages?.length) {
    const lang = navigator.languages[0];
    const languageRegionRegex = /[a-zA-Z]{2}-[a-zA-Z]{2}/;

    return languageRegionRegex.test(lang) ? lang.substring(0, 2) : lang;
  }
  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    LANGUAGE_CODE.EN
  );
};

/**
 * Virtual keyboard options
 */

const ORIGINAL_VIEWPORT_HEIGHT = window.innerHeight;

function isVirtualKeyboardVisible() {
  return (
    'ontouchstart' in window && window.innerHeight < ORIGINAL_VIEWPORT_HEIGHT
  );
}

/**
 * State Name
 */

const NAME = 'App';

/**
 * @typedef {Object} AppState
 * @property {LanguageCode} language
 * @property {boolean} authenticated
 * @property {ID} userId
 * @property {UserRole} userRole
 * @property {UserAccount} userAccount
 * @property {UserProfile} userProfile
 * @property {boolean} virtualKeyboardVisible
 */

/**
 * Initial State
 * @returns {AppState}
 */
function getInitialState() {
  return {
    language: getNavigatorLanguage(),
    authenticated: false,
    userId: null,
    userRole: null,
    userAccount: null,
    userProfile: null,
    virtualKeyboardVisible: isVirtualKeyboardVisible(),
  };
}

/**
 * State Proptypes
 */
export const StatePropTypes = {
  language: PropTypes.LanguageCode,
  authenticated: PropTypes.bool,
  userId: PropTypes.ID,
  userRole: PropTypes.UserRole,
  userAccount: PropTypes.UserAccount,
  userProfile: PropTypes.UserProfile,
  virtualKeyboardVisible: PropTypes.bool,
};

/**
 * State Definition
 */

export const AppState = createState(NAME, getInitialState, StatePropTypes);

const { Manager: StateManager } = AppState;

/**
 * Initialize App State
 */
export function initializeAppState() {
  window.addEventListener(
    'resize',
    debounce(
      () => {
        StateManager.setState({
          virtualKeyboardVisible: isVirtualKeyboardVisible(),
        });
      },
      0,
      { leading: true },
    ),
  );

  return new Promise((resolve) => {
    let firstRun = true;

    onIdTokenChanged(auth, async (authUser) => {
      if (!authUser) {
        StateManager.setState(getInitialState);

        if (firstRun) {
          firstRun = false;
          resolve();
        }

        return;
      }

      const idToken = await authUser.getIdTokenResult();

      const userId = authUser.uid;

      const userRole = idToken.claims.role || USER_ROLE.DEFAULT;

      const userAccount = {
        id: userId,
        role: userRole,
        displayName: authUser.displayName || '',
        photoURL: authUser.photoURL || '',
        email: authUser.email,
        emailVerified: true,
      };

      const userProfile = await fetchUserProfile(userId);

      if (!userAccount.displayName) {
        userAccount.displayName = userProfile.name || '';
      }

      StateManager.setState((state) => ({
        language: (userProfile && userProfile.language) || state.language,
        authenticated: true,
        userId,
        userRole,
        userAccount,
        userProfile,
      }));

      if (firstRun) {
        firstRun = false;
        resolve();
      }
    });
  });
}

/**
 * refreshes authentication token
 */
export function refreshAuthTokenAndGlobalAppState() {
  return getIdToken(auth.currentUser, true);
}

/**
 * changes and save user language
 */
export async function changeUserLanguage(language) {
  const { userId } = StateManager.getState();

  StateManager.setState({
    language,
  });

  if (userId) {
    const ref = doc(firestore, `${USERS_COLLECTION}/${userId}`);
    await updateDoc(ref, { language });

    await refreshAuthTokenAndGlobalAppState();
  }
}

/**
 * @param {ID} chapterId
 */
export async function addBoughtChapterAndMethodToUserProfile(
  methodId,
  chapterIds,
) {
  const { userId } = AppState.Manager.getState();
  const userRef = doc(firestore, `${USERS_COLLECTION}/${userId}`);
  const userDoc = await getDoc(userRef);
  let boughtChapterIds = [];
  let boughtMethodIds = [];

  if (!userDoc.data().boughtChapterIds) {
    boughtChapterIds = chapterIds;
  } else {
    ({ boughtChapterIds } = userDoc.data());
    chapterIds.forEach((chapterId) => {
      if (boughtChapterIds.indexOf(chapterId) === -1) {
        boughtChapterIds.push(chapterId);
      }
    });
  }

  if (!userDoc.data().boughtMethodIds) {
    boughtMethodIds = [methodId];
  } else {
    ({ boughtMethodIds } = userDoc.data());
    if (boughtMethodIds.indexOf(methodId) === -1) {
      boughtMethodIds.push(methodId);
    }
  }

  await updateDoc(userRef, {
    boughtChapterIds,
    boughtMethodIds,
  });

  await refreshAuthTokenAndGlobalAppState();
}

/**
 * @param {ID} chapterId
 */
export function checkIfUserBoughtChapter(chapterId) {
  const { userProfile } = StateManager.getState();

  if (!userProfile.boughtChapterIds) {
    return false;
  }

  if (userProfile.boughtChapterIds.indexOf(chapterId) >= 0) {
    return true;
  }

  return false;
}

/**
 * @param {ID} methodId
 */
export function checkIfUserBoughtMethod(methodId) {
  const { userProfile } = StateManager.getState();

  if (!userProfile.boughtMethodIds) {
    return false;
  }

  if (userProfile.boughtMethodIds.indexOf(methodId) >= 0) {
    return true;
  }

  return false;
}

/**
 * @param {ID} methodId
 */
export async function addMethodToRecentlyUsedSection(methodId) {
  const { userId } = AppState.Manager.getState();

  const userRef = doc(firestore, `${USERS_COLLECTION}/${userId}`);
  const userDoc = await getDoc(userRef);
  let recentlyUsedMethodIds = [];

  if (!userDoc.data().recentlyUsedMethodIds) {
    recentlyUsedMethodIds = [methodId];
  } else {
    ({ recentlyUsedMethodIds } = userDoc.data());
    if (recentlyUsedMethodIds.indexOf(methodId) >= 0) {
      recentlyUsedMethodIds.splice(recentlyUsedMethodIds.indexOf(methodId), 1);
    }
    recentlyUsedMethodIds.unshift(methodId);
  }

  // Update only four elements in the list.
  await updateDoc(userRef, {
    recentlyUsedMethodIds: recentlyUsedMethodIds.slice(0, 4),
  });

  await refreshAuthTokenAndGlobalAppState();
}
